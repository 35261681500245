import Vue from 'vue'
import Vuex from 'vuex'
import { user } from './User.js';
import { chat } from './Chat.js';
import { inventaire } from './Inventaire.js';
import { setting } from './Setting.js';

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    chat,
    inventaire,
    setting
  },
  plugins: [
    createPersistedState()
  ],

})
