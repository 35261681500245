
export const chat = {
    state: {
        messages: [],
        context: {},
        bookmarks: [],
    },
    mutations: {
        ADD_BOOKMARK(state, message) {
            state.bookmarks.push(message);
        },
        REMOVE_BOOKMARK(state, messageId) {
            state.bookmarks = state.bookmarks.filter(m => m.id !== messageId);
        },
        ADD_MESSAGE(state, message) {
            let id = Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1)
            state.messages.push({ ...message, id });
            // if (state.messages.length > 5) {
            //     state.messages.shift();
            // }
        },
        UPDATE_MESSAGES(state, messages) {
            state.messages = messages
        },
        UPDATE_BOOKMARKS(state, messages) {
            state.bookmarks = messages
        },
        UPDATE_CONTEXT(state, context) {
            state.context = context
        },
        CLEAR_MESSAGES(state, context) {
            state.context = {}
        }
    },
    actions: {
        toggleBookmark({ commit, state }, message) {
            const isBookmarked = state.bookmarks.some(m => m.id === message.id);
            if (isBookmarked) {
                commit('REMOVE_BOOKMARK', message.id);
            } else {
                commit('ADD_BOOKMARK', message);
            }
        },
        addMessage({ commit }, message) {
            commit('ADD_MESSAGE', message);
        },
        updateContext({ commit }, context) {
            commit('UPDATE_CONTEXT', context);
        },
        updateMessages({ commit }, messages) {
            commit('UPDATE_MESSAGES', messages);
        },
        updateBookmarks({ commit }, messages) {
            commit('UPDATE_BOOKMARKS', messages);
        },
        clearContext({ commit }) {
            commit('CLEAR_MESSAGES');

        }
    },
    getters: {
        context: state => state.context,
        messages: state => state.messages,
        bookmarks: state => state.bookmarks
    },
};