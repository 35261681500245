import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/autologin-invited/:uid/inventaire/:id',
    name: 'AutologinInvited',
    component: () => import('../components/AutologinInvited.vue'),
  },
  {
    path: '/autologin/:uid/inventaire/:id/provider/:provider',
    name: 'Autologin',
    component: () => import('../components/Autologin.vue'),
  },
  {
    path: '/concept',
    name: 'concept',
    component: {
      template: '<div id="vue3-landing-container"></div>',
    },
    beforeEnter: (to, from, next) => {
      window.location.href = '/dist/index.html';
    },
  },
  {
    path: '/',
    name: 'Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Carte.vue'),
  },
  {
    path: '/liste-inventaires',
    name: 'ListeInventaires',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/ListeInventaires.vue'),
  },
  {
    path: '/tchat',
    name: 'Tchat',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Tchat.vue'),
  },


  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../components/Home.vue'),
  // },
  // {
  //   path: '/carto',
  //   name: 'Carto',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ '../components/CarteA.vue'),
  // },
  {
    path: '/premium',
    name: 'Premium',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Premium.vue'),
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Documentation.vue'),
  },
  {
    path: '/premium-success',
    name: 'PremiumSuccess',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PremiumSuccess.vue'),
  },
  {
    path: '/premium-cancel',
    name: 'PremiumCancel',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PremiumCancel.vue'),
  },

  {
    path: '/account',
    name: 'Account',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Account.vue'),
  },


  {
    path: '/carte',
    name: 'Carte',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Carte.vue'),
  },
  {
    path: '/add',
    name: 'Add',
    component: () => import('../components/Add.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../components/Login.vue'),
  },
  {
    path: '/concept',
    name: 'Concept',
    component: () => import('../components/Concept.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../components/About.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../components/Register.vue'),
  },
  {
    path: '/register-premium',
    name: 'RegisterPremium',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PremiumRegister.vue'),
  },
  {
    path: '/import',
    name: 'Import',
    component: () => import('../components/Import.vue'),
  },
  {
    path: '/static/faq',
    name: 'Faq',
    component: () => import('../components/Faq.vue'),
  },

  {
    path: '/confirm-account',
    name: 'Confirm',
    component: () => import('../components/Confirm.vue'),
  },
  {
    path: '/static/infos',
    name: 'Infos',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Infos.vue'),
  },
  {
    path: '/static/contact',
    name: 'Contact',
    component: () => import('../components/Contact.vue'),
  },
  {
    path: '/static/mentions-legales',
    name: 'Mentions',
    component: () => import('../components/Mentions.vue'),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../components/Reset.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../components/ResetPassword.vue'),
  },
  {
    path: '/map/:id',
    name: 'Load',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Carte.vue'),
  },
  {
    path: '/inventaire/:id/:uid?/:readonly?',
    name: 'Detail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../components/Carte.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../components/NotFound.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('user');
  let listeRoute = ['Login', 'Concept', 'Register', 'Import', 'Confirm', 'Faq', 'Contact', 'Mentions', 'Reset', 'Test', 'ResetPassword', 'Autologin']
  if (token) {
    token = JSON.parse(localStorage.getItem('user'));
  }

  // pas connecté mais a une une readonly

  else if (to.path.endsWith('/readonly')) {
    // Vous pouvez ici ajouter votre logique spécifique pour cette condition
    console.log('La route se termine par "readonly"');
    // Par exemple, vérifier si l'utilisateur a le droit d'accéder à cette route en mode lecture seule
    return next(); // Rediriger si l'utilisateur n'a pas les droits
  }

  if (token && to.name == 'Add' && token.admin !== true) {
    return router.push({ name: 'Home' });
  }
  if (!token &&
    !listeRoute.includes(to.name)
  ) {
    return router.push({ name: 'Login' });
  } else {
    return next();
  }
});

export default router;
