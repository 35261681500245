import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.task._id)?_c(VListItem,{key:_vm.task._id,attrs:{"dense":"","disabled":_vm.task.enabled}},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"disabled":_vm.task.enabled,"small":"","label":`Fait`},on:{"change":function($event){return _vm.changeTask(_vm.task)}},model:{value:(_vm.task.enabled),callback:function ($$v) {_vm.$set(_vm.task, "enabled", $$v)},expression:"task.enabled"}})],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-flex d-flex justify-space-between"},[_c('span',[(_vm.task.important == true)?_c(VIcon,{attrs:{"slot":"opposite","x-small":"","right":"","size":"20","color":"red"},slot:"opposite"},[_vm._v("mdi-alert-decagram")]):_vm._e(),_vm._v(" "+_vm._s(_vm.task.title))],1),_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.task.enabled,"x-small":"","fab":"","color":"primary","rounded":"","outlined":""},on:{"click":function($event){return _vm.detail(_vm.task)}}},[_c(VIcon,[_vm._v("mdi-eye")])],1)],1),_c(VListItemSubtitle,{staticClass:"text-caption"},[_c(VIcon),(_vm.task.dateRangeFormatted)?_c('span',[_vm._v(_vm._s(_vm.task.dateRangeFormatted))]):_vm._e()],1),(_vm.task.parcelles.length)?_c(VRow,_vm._l((_vm.task.parcelles),function(parcelle){return _c(VCol,{key:parcelle._id},[_c(VChip,{attrs:{"disabled":_vm.task.enabled,"label":"","small":"","color":"info"}},[_vm._v(" "+_vm._s(parcelle.name)+" ")])],1)}),1):_vm._e(),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"disabled":_vm.task.enabled,"append-icon":"mdi-pencil","label":"Ajouter une note","outlined":"","rows":"2","multi-line":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();return _vm.add.apply(null, arguments)}},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }