<template>
  <div>
    <div v-if="($route.name == 'Login'  || $route.name == 'Reset' || $route.name == 'ResetPassword')" class="bg"></div>

    <v-snackbar v-model="snackbar">
      Vos données ont été mise à jour
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Fermer</v-btn>
      </template>
    </v-snackbar>


    <v-snackbar v-model="snackTasks" :vertical="true" rounded="pill" absolute bottom color="deep-purple accent-4"
      elevation="24">
      {{ snackMess }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackTasks = false">Fermer</v-btn>
      </template>
    </v-snackbar>

    <v-dialog fullscreen v-model="dialogTasksModal" hide-overlay :min-width="500">
      <v-card>
        <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-calendar-check</v-icon> Tâches d'aujourd'hui</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogTasksModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container class="d-flex justify-center">
          <v-list type="primary" flat dense three-line>
            <template v-for="task, index in tasks">
              <Notif @detail="viewDetail" @addNote="addNote" :task="task"></Notif>
              <v-divider v-if="index < tasks.length - 1" :key="index" :inset="true"></v-divider>
            </template>
          </v-list>
        </v-container>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogDetail">
      <v-card color="primary">
        <v-toolbar dark color="secondary white--text">
          <v-toolbar-title><v-icon>mdi-calendar-check</v-icon> Détail de la tâche</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-sheet v-if="taskSelected" class="pa-3">
          <v-row>
            <v-spacer></v-spacer>

            <v-chip class="ma-2" color="primary" rounded>
              <v-icon left>{{ taskSelected.enabled ?
                "mdi-calendar-check" :
                "mdi-calendar"
                }}</v-icon>
              <span class="mr-1">{{ taskSelected.dateRangeFormatted
                }}</span>
            </v-chip>
          </v-row>


          <v-row class="pa-2">
            <h3 class="text-h6">
              <v-icon v-if="taskSelected.important == true" size="30" color="red">mdi-alert-decagram</v-icon>
              {{ taskSelected.title }}
            </h3>
            <v-spacer></v-spacer>
            <v-switch @change="changeTask(taskSelected)" v-model="taskSelected.enabled" inset
              :label="`Fait`"></v-switch>
          </v-row>

          <v-row dense justify="end" class="pa-2" v-if="taskSelected.parcelles.length">
            <v-chip label small class="ma-1" color="info">{{ taskSelected.selectedStep }}</v-chip>
            <v-chip class="ma-1" v-for="parcelle in taskSelected.parcelles" :key="taskSelected._id" label small outlined
              color="secondary">
              {{ parcelle.name }}
            </v-chip>
          </v-row>



          <v-row class="pa-2 ma-2" dense>
            <p v-html="taskSelected.description"></p>
          </v-row>

          <v-row v-if="taskSelected.file" justify="end" class="ma-2">
            <v-btn class="secondary" outlined rounded dark @click="openFile(taskSelected)"><v-icon>mdi-file-eye</v-icon>
              Voir le
              fichier
              joint</v-btn>
          </v-row>

          <v-row dense justify="end" class="pa-2" v-if="taskSelected.users.length">
            <v-col>
              <v-tooltip bottom v-for="user in taskSelected.users" :key="user._id">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ma-1" v-on="on" fab small color="primary">
                    {{ user.firstname[0] + user.lastname[0] }}
                  </v-btn>
                </template>
                <span> {{ user.firstname + user.lastname }}</span>
              </v-tooltip>
            </v-col>
          </v-row>


        </v-sheet>
      </v-card>
    </v-dialog>

    <!-- <v-app id="app" app elevate-on-scroll> -->
    <v-app app>

      <v-navigation-drawer temporary v-if="isConnect && !readOnly" v-model="drawer" fixed @click.stop="drawer = false">
        <img @click="refreshHome" class="logoHome ml-4 pt-4" width="180" height="100"
          src="@/assets/Logo Cartovia FT.png" />
        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item-group v-model="group" active-class="primary--text text--accent-4">
            <v-list-item @click="refreshHome">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Accueil</v-list-item-title>
            </v-list-item>



            <v-list-item to="/static/infos">
              <v-list-item-icon>
                <v-icon>mdi-information</v-icon>
              </v-list-item-icon>
              <v-list-item-title>À propos</v-list-item-title>
            </v-list-item>

            <v-list-item to="/static/contact">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>


            <v-list-item v-if="!user.subscribed" to="/premium" class="amber" dark>
              <v-list-item-icon>
                <v-icon class="pa-1">mdi-crown</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Passer Premium</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- Liste de déconnexion positionnée en bas -->
        <v-list class="mt-auto" nav dense>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-lock-open</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Se déconnecter</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar :height="60" app color="white">
        <v-app-bar-nav-icon
          v-if="isConnect && !readOnly && ($route.name == 'Detail' || $route.name == 'Home') && user.reader != true"
          @click="drawer = true"> </v-app-bar-nav-icon>


        <v-toolbar-title class="mx-auto pt-2">
          <img class="logoHome mt-1" @click="refreshHome" width="130" height="60" src="@/assets/Logo Cartovia FT.png" />
        </v-toolbar-title>
        <v-btn v-if="nbTasks > 0 && ($route.name == 'Detail' || $route.name == 'Home') && user.reader != true"
          @click="getTasks" class="ringed animate-bell" color="amber" fab small icon><v-badge :content="nbTasks" top
            right overlap color="red"><v-icon>mdi-bell-ring</v-icon></v-badge></v-btn>

        <v-btn v-else-if="($route.name == 'Detail' || $route.name == 'Home') && user.reader != true" color="grey" fab
          small icon><v-icon>mdi-bell</v-icon></v-btn>

        <v-btn id="v-step-0" v-if="isConnect && !readOnly && ($route.name == 'Detail' || $route.name == 'Home')" icon
          color="secondary" dark @click="viewToolbar">
          <v-icon>mdi-apps</v-icon> <!-- Remplacez mdi-icon-name par l'icône de votre choix -->
        </v-btn>
      </v-app-bar>

      <v-main id="main" class="no-swipe">
    
        <router-view :key="routeKey" />
      </v-main>

      <v-footer dark padless>
        <v-card flat tile color="primary" width="100%" class="white--text text-center pt-1">
          <!-- <v-card-text>
            <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
              <v-icon size="24px">
                {{ icon }}
              </v-icon>
            </v-btn>
          </v-card-text> -->

          <img @click="refreshHome" class="logoHome ml-1 pt-1" width="180" height="90" src="@/assets/logo_blanc.png" />


          <!-- <v-card-text class="white--text">
            {{ new Date().getFullYear() }} — Made with love by <a target="_blank" style="color: white"
              href="http://taiwa.fr"><strong>Taiwa</strong></a>
          </v-card-text> -->
        </v-card>
      </v-footer>

    </v-app>

  </div>
</template>

<style>
#main {
  padding-top: 69px !important;
}

.opa {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>

<script>
import xhr from './plugins/axios';
import Notif from './components/Notif.vue'
import { v4 as uuidv4 } from "uuid";
import { mapGetters, mapActions } from 'vuex';

import "./App.scss";

export default {
  name: "Login",
  components: {
    Notif
  },
  data() {
    return {
      connected: false,
      data: null,
      devices: [],
      gpsData: null,
      note: '',
      dialogTasksModal: false,
      snackTasks: false,
      snackMess: '',
      nbTasks: 0,
      tasks: [],
      readOnly: false,
      routeKey: 0,
      rail: false,
      selectedInventaireObj: {},
      drawer: false,
      icons: [
        'mdi-email',
        'mdi-linkedin',
        'mdi-youtube',
      ],
      dialogMenu: false,
      nav: [
        {
          icon: 'home',
          text: 'Home',
          title: 'Back to Home page',
          active: true
        },
        {
          icon: 'info',
          text: 'About',
          title: 'About this demo',
          active: false
        },
        {
          icon: 'assignment_turned_in',
          text: 'Todos',
          title: 'Some stuff that needs doing',
          active: false
        },
      ],
      snackbarRooms: false,
      valid: true,
      manager: {
        id: uuidv4(),
        nom: "",
        prenom: "",
        email: "",
        password: "",
        property: "",
        admin: false,
      },
      loadingSync: false,
      dialog: false,
      dialogDetail: false,
      taskSelected: null,
      connected: localStorage.getItem("user"),
      snackbar: false,
      annonce: "",
      select: "",
      dialogEditManager: false,

    };
  },
  watch: {
    $route(to, from) {

      if (to.params.id) {
        if (to.params.readonly && to.params.readonly == 'readonly') {
          this.readOnly = true
          if (to.params.uid) {
            this.loadTasks(to.params.uid)
          }
        }
      }

      if (from.name == "Edit") {
        this.snackbar = true;
      }
    },
  },
  async created() {

    if (localStorage.getItem("rooms")) {
      this.rooms = JSON.parse(localStorage.getItem("rooms"));
    }

    let user = localStorage.getItem("user");
  
    if (user) {
      this.user = JSON.parse(user);
      // console.log("this.user reader...", this.user.reader);

      // this.loadTasks(this.user.uid)
    }

  },

  computed: {
    ...mapGetters({
      viewModal: 'viewModal' // 'getColonnes' est le nom du getter défini dans votre store
    }),
    isConnect() {
      return this.user && this.user.email
    },
  },
  methods: {
    connectToNTRIP() {
      const url = 'wss://polaris-eu.pointonenav.com:2101';
      const mountpoint = 'POLARIS_LOCAL'; // Remplacez par votre point de montage
      const username = 'f9s3u7j94x'; // Remplacez par votre nom d'utilisateur
      const password = '52djdfbf5t'; // Remplacez par votre mot de passe

      const auth = btoa(`${username}:${password}`);
      const request = `GET /${mountpoint} HTTP/1.1\r\n` +
        `Authorization: Basic ${auth}\r\n` +
        `\r\n`;

      const socket = new WebSocket(url);

      socket.onopen = () => {
        console.log('Connexion établie');
        this.connected = true;
        socket.send(request);
      };

      socket.onmessage = (event) => {
        console.log('Données reçues : ', event.data);
        this.data = event.data;
      };

      socket.onclose = () => {
        console.log('Connexion fermée');
        this.connected = false;
      };

      socket.onerror = (error) => {
        console.error('Erreur de connexion : ', error);
        this.connected = false;
      };
    },
    handleCharacteristicValueChanged(event) {
      const value = new TextDecoder().decode(event.target.value);
      const [latitude, longitude] = value.split(',');

      console.log("this.gpsData",);
      this.gpsData = {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude)
      };

      console.log("this.gpsData ", this.gpsData);
    },
    openFile(task) {
      if (task && task.file) {
        const fileUrl = `${process.env.VUE_APP_BASE_URL}uploads/inventaires/${task.invId}/${encodeURIComponent(task.file)}`;
        window.open(fileUrl, '_blank');
      }
    },

    async viewDetail(task) {
      this.taskSelected = task
      this.dialogDetail = true
    },
    async addNote(task, note) {
      let res = await xhr.post('/add-note-task', {
        task: task,
        inv: task.invId,
        note: note
      });
      this.dialogTasksModal = false
      this.snackMess = "Votre note a bien été envoyée"
      this.snackTasks = true
    },
    async changeTask(task) {
      let res = await xhr.post('/set-task', {
        task: task,
        inv: task.invId,
      });
      this.snackMess = "Votre tâche est accomplie 🚀"
      this.snackTasks = true
    },
    getTasks() {
      this.snackTasks = true
      console.log(this.tasks);
      this.snackMess = "Vous avez " + this.nbTasks + " tâches aujourd'hui: "
      this.snackMess += this.tasks.map(e => e.title).join(' , ')

      this.dialogTasksModal = true
    },
    async loadTasks(uid) {
      let res = await xhr.post("/tasks-to-date", {
        uid: uid,
        date: new Date()
      })
      this.nbTasks = res.data.tasks.length
      this.tasks = res.data.tasks

    },
    viewToolbar() {
      this.$store.dispatch('toggleToolbar', !this.viewModal);
    },
    refreshHome() {
      this.routeKey++;
      this.$router.push('/')
    },
    loading(inventaire) {
      this.$router.push({ path: `/map/${inventaire}` })
      this.dialogMenu = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    goto(refName) {
      const element = document.getElementById(refName);
      // element.scrollIntoView();
      this.$vuetify.goTo(element);
      // goTo(refName);
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    add() {
      this.$router.push("/add");
    },
  },
};
</script>

<style>
@keyframes ringBell {

  0%,
  100% {
    transform: rotate(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: rotate(-10deg);
  }

  20%,
  40%,
  60%,
  80% {
    transform: rotate(10deg);
  }
}

.animate-bell {
  animation: ringBell 2s 2;
}
</style>