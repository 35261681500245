export const setting = {
    state: {
        dialogPosition: {
            x: 0,
            y: 0
        },
    },
    mutations: {
        SET_DIALOG_POSITION(state, position) {
            state.dialogPosition = position;
        },
    },
    actions: {
        updateDialogPosition({ commit }, position) {
            commit('SET_DIALOG_POSITION', position);
        },
    },
    getters: {

    },
};