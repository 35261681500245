import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify/lib';
import router from './router';
import fr from 'vuetify/es5/locale/fr';
import 'vue-material-design-icons/styles.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import './registerServiceWorker'
// import VueMapbox from "vue-mapbox";
// import VueExcelEditor from 'vue-excel-editor'
import VueTour from 'vue-tour'
import store from './store'
import wysiwyg from "vue-wysiwyg";

require('vue-tour/dist/vue-tour.css')

Vue.use(VueTour)
Vue.config.silent = true;

// import { StripePlugin } from '@vue-stripe/vue-stripe';

Vue.use(Vuetify);
Vue.use(wysiwyg, {
    hideModules: { "code": true, "image": true, "image": true, },
});

// Vue.use(VueMapbox, { mapboxgl: Mapbox });
// Vue.use(VueExcelEditor)

// const options = {
//   pk: process.env.STRIPE_PUBLISHABLE_KEY,
//   stripeAccount: process.env.STRIPE_ACCOUNT,
//   apiVersion: process.env.API_VERSION,
//   locale: process.env.LOCALE,
// };

// Vue.use(StripePlugin, options);


let vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    lang: {
        locales: { fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#AB3443',
                secondary: '#F46370',
                accent: '#380E07',
                error: '#EF7D00',
                success: '#2e8b57'
            },
        },
    },
});


Vue.config.productionTip = false;

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app');