import axios from "axios";
const username = "CARTOVIA_-a3f5hDD43g56dfgehD5_6dfc4h568f";
const password = "W354SSyhj2ty_5h-456g24k56_A468zkj356dfv135h35";
const token = `${username}:${password}`;
const encodedToken = Buffer.from(token).toString("base64");

const xhr = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  // baseURL: "https://cartovia.fr/api/",
  headers: { Authorization: "Basic " + encodedToken },
});

// xhr.defaults.headers.common = {'Authorization': `bearer ${ localStorage.getItem('token')}`}

// Add a response interceptor
xhr.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default xhr;
