let todayDate = new Date().toISOString().slice(0, 10);

export const inventaire = {
    state: {
        viewItem: 'operateur',
        colonnes: [],
        datas: [],
        essencesSelected: [],
        hauteursSelected: [],
        diametresSelected: [],
        volumesSelected: [],
        tarifsSelected: [],
        domaineSelected: [],
        parcellesSelected: [],
        etatSelected: [],
        cepagesSelected: [],
        operateursSelected: [],
        datesSelected: [],
        devicesSelected: [],
        lieuxDitsSelected: [],
        typeMap: 'satellite-streets-v11',
        rail: false,
        hauteurVigne: 1,
        nameInv: '',
        dateInv: todayDate,
        invId: null,
        selectDomain: 'vigneron',
        viewModal: false,
    },
    mutations: {
        SET_SELECTION(state, { key, value }) {
            state[key] = value;
        },
        SET_DOMAINE_SELECTED(state, domaines) {
            state.domaineSelected = domaines;
        },
        ADD_ITEM(state, item) {
            state.colonnes.push(item);
        },
        REMOVE_ITEM(state, index) {
            state.colonnes.splice(index, 1);
        },
        SET_ITEM(state, colonnes) {
            state.colonnes = colonnes;
        },
        RESET_ITEM(state) {
            state.colonnes = []
        },
        DELETE_COLONNE(state, colonneName) {
            if (state.colonnes.length) {
                state.colonnes = state.colonnes.filter(e => {
                    if (e.col != colonneName) {
                        return true
                    } else if (e && typeof e == 'string' && e != colonneName) {
                        return true
                    } else if (e && typeof e === 'object' && e.col != colonneName) {
                        return true
                    }
                });
            }

        },
        SET_NAME_INV(state, newNameInv) {
            state.nameInv = newNameInv;
        },
        SET_DATE_INV(state, newDateInv) {
            if (!newDateInv || newDateInv.length < 2) {
                state.dateInv = "";
            } else {
                state.dateInv = newDateInv;
            }
        },
        SET_SELECT_DOMAIN(state, newValue) {
            state.selectDomain = newValue.value;
        },
        TOGGLE_TOOLBAR(state, newValue) {
            state.viewModal = newValue;
        },
        SET_DATAS(state, datas) {
            state.datas = datas;
        },
        updateIdCol(state, newValue) {
            state.invId = newValue
        },
        updateColonne(state, payload) {
            const index = state.colonnes.findIndex(c => c.name === payload.colonne.name);
            if (index !== -1) {
                state.colonnes = state.colonnes.map((colonne, i) => {
                    if (i === index) {
                        // formatage dfu name de colonnes sur la base de connaissance des colonnes KNOWNS
                        return {
                            ...colonne, ...payload.colonne, ...payload.updates,
                            name: payload.updates.selectItem
                        };
                    }

                    return colonne; // Retourne l'élément inchangé pour les autres indices
                });

                //                state.colonnes = state.colonnes.splice(index, 1, { ...payload.colonne, ...payload.updates });
            }
        }


    },
    actions: {
        updateSelection({ commit }, payload) {
            commit('SET_SELECTION', payload);
        },
        updateDomaineSelected({ commit }, domaines) {
            commit('SET_DOMAINE_SELECTED', domaines);
        },
        updateIdCol({ commit }, payload) {
            commit('updateIdCol', payload);
        },
        updateColonne({ commit }, payload) {
            commit('updateColonne', payload);
        },

        updateDateInv({ commit }, newDateInv) {
            commit('SET_DATE_INV', newDateInv);
        },
        updateDatas({ commit }, datas) {
            commit('SET_DATAS', datas);
        },
        updateSelectDomain({ commit }, newValue) {
            commit('SET_SELECT_DOMAIN', newValue);
        },
        updateNameInv({ commit }, newNameInv) {
            commit('SET_NAME_INV', newNameInv);
        },
        removeColonne({ commit }, colonneName) {
            commit('DELETE_COLONNE', colonneName);
        },
        addItem({ commit }, item) {
            commit('ADD_ITEM', item);
        },
        removeItem({ commit }, index) {
            commit('REMOVE_ITEM', index);
        },
        updateColonnes({ commit }, colonnes) {
            commit('SET_ITEM', colonnes);
        },
        resetColonnes({ commit }) {
            commit('RESET_ITEM');
        },
        toggleToolbar({ commit }, value) {
            commit('TOGGLE_TOOLBAR', value);
        },
    },
    getters: {
        invId: state => state.invId,
        viewItem: state => state.viewItem,
        dateInv: state => state.dateInv,
        nameInv: state => state.nameInv,
        colonnes: state => state.colonnes,
        datas: state => state.datas,
        selectDomain: state => state.selectDomain,
        domaineSelected: state => state.domaineSelected,
        viewModal: state => state.viewModal,

    },
};