import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const user = {
    state: {
        user: null,
        name: '',
        type: null,
        verified: false,
        surname: '',
        email: '',
        nameCompagny: '',
        tel: '',
        uid: '',
        newsletter: false,
        notification: false,
    },
    mutations: {
        SET_SELECTION(state, { key, value }) {
            state[key] = value;
        },
        SET_VERIFIED(state, verified) {
            state.verified = verified;
        },
        SET_USER(state, user) {
            state.user = user;
        },
        CLEAR_USER(state) {
            state.user = null;
        },
        SET_TYPE(state, type) {
            state.type = type;
        },
        setName(state, nom) {
            state.name = nom;
        },
        setSurname(state, surname) {
            state.surname = surname;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setNameCompagny(state, compagny) {
            state.nameCompagny = compagny
        },
        setTel(state, tel) {
            state.tel = tel;
        },
        setUid(state, uid) {
            state.uid = uid;
        },

    },
    actions: {
        updateSelection({ commit }, payload) {
            commit('SET_SELECTION', payload);
        },
        updateType({ commit }, payload) {
            commit('SET_TYPE', payload);
        },
        setVerified({ commit }, verified) {
            commit('SET_VERIFIED', verified);
        },
        setUser({ commit }, user) {
            commit('SET_USER', user);
        },
        clearUser({ commit }) {
            commit('CLEAR_USER');
        },
        updateName({ commit }, name) {
            commit('setName', name);
        },
        updateNameCompagny({ commit }, name) {
            commit('setNameCompagny', name);
        },
        updateSurname({ commit }, name) {
            commit('setSurname', name);
        },
        updateEmail({ commit }, email) {
            commit('setEmail', email);
        },
        updateTel({ commit }, tel) {
            commit('setTel', tel);
        },
        updateUid({ commit }, uid) {
            commit('setUid', uid);
        }
    },
    getters: {
        user: state => state.user,
        type: state => state.type,
        newsletter: state => state.newsletter,
        notification: state => state.notification,
        isAuthenticated: state => !!state.user,
        getName: state => state.name,
        getSurname: state => state.surname,
        getVerified: state => state.verified,
        getNameCompagny: state => state.nameCompagny,
        getEmail: state => state.email,
        getTel: state => state.tel,
        getUid: state => state.uid
    },
};
