<template>
    <v-list-item v-if="task._id" dense :disabled="task.enabled" :key="task._id">
        <v-list-item-action>
            <v-checkbox :disabled="task.enabled" small @change="changeTask(task)" v-model="task.enabled"
                :label="`Fait`"></v-checkbox>
        </v-list-item-action>

        <v-list-item-content>
            <v-list-item-title class="d-flex d-flex justify-space-between">
                <span><v-icon x-small right slot="opposite" v-if="task.important == true" size="20"
                        color="red">mdi-alert-decagram</v-icon>
                    {{ task.title }}</span>
                <v-spacer></v-spacer>
                <v-btn @click="detail(task)" :disabled="task.enabled" x-small fab color="primary" rounded
                    outlined><v-icon>mdi-eye</v-icon></v-btn>
            </v-list-item-title>

            <v-list-item-subtitle class="text-caption">
                <v-icon>
                </v-icon><span v-if="task.dateRangeFormatted">{{ task.dateRangeFormatted }}</span>

            </v-list-item-subtitle>

            <v-row v-if="task.parcelles.length">
                <v-col v-for="parcelle in task.parcelles" :key="parcelle._id">
                    <v-chip :disabled="task.enabled" label small color="info">
                        {{ parcelle.name }}
                    </v-chip>

                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field :disabled="task.enabled" append-icon="mdi-pencil" v-model="note"
                        label="Ajouter une note" outlined rows="2" multi-line @keydown.enter.stop="add"></v-text-field>
                </v-col>
            </v-row>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import xhr from '../plugins/axios';
export default {
    name: 'Notif',
    components: {
    },
    data() {
        return {
            note: ''
        }

    },
    created() {
    },
    methods: {
        add() {
            this.$emit('addNote', this.task, this.note)
            this.note = ''
        },
        detail(task) {
            this.$emit('detail', task)
        }
    },
    props: ['task'],


}
</script>